.customtab {
  li {
      a.nav-link {
          border: 0px;
          padding: 15px 20px;
          color: $body-color;
          &.active {
              border-bottom: 2px solid $themecolor;
              color: $themecolor;
          }
          &:hover {
              color: $themecolor;
          }
      }
  }
}

.vtabs {
  display: table;

  > .tabs-vertical {
      width: 150px;
      border-bottom: 0px;
      border-right: 1px solid $border-color;
      display: table-cell;
      vertical-align: top;
      li .nav-link {
          color: $gray-800;
          margin-bottom: 10px;
          border: 0px;
          border-radius: $border-radius 0 0 $border-radius;
      }
  }

  > .tab-content {
      display: table-cell;
      padding: 20px;
      vertical-align: top;
  }
}

.tabs-vertical li .nav-link.active,
.tabs-vertical li .nav-link:hover,
.tabs-vertical li .nav-link.active:focus {
  background: $themecolor;
  border: 0px;
  color: $white;
}


/*Custom vertical tab*/

.customvtab > .tabs-vertical li .nav-link.active,
.customvtab > .tabs-vertical li .nav-link:hover,
.customvtab > .tabs-vertical li .nav-link:focus {
  background: $white;
  border: 0px;
  border-right: 2px solid $themecolor;
  margin-right: -1px;
  color: $themecolor;
}

.tabcontent-border {
  border: 1px solid #ddd;
  border-top: 0px;
}

.customtab2 {
  li {
      a.nav-link {
          border: 0px;
          margin-right: 3px;
          color: $body-color;
          &.active {
              background: $themecolor;
              color: $white;
          }
          &:hover {
              color: $white;
              background: $themecolor;
          }
      }
  }
}

.tab-scoring {

}

.tab-tasks {
  margin-left: -20px;
  margin-right: -20px;
}