/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

/**
 * Table Of Content
 *
 *  1. Color system
 *  2. Options
 *  3. Body
 *  4. Typography
 *  5. Breadcrumbs
 *  6. Cards
 *  7. Dropdowns
 *  8. Buttons
 *  9. Typography
 *  10. Progress bars
 *  11. Tables
 *  12. Forms
 *  14. Component
*/

// vendors
@import '~antd/dist/antd.css';
@import '~react-date-range/dist/styles.css'; // main style file
@import '~react-date-range/dist/theme/default.css'; // theme css file
@import '~react-toastify/dist/ReactToastify.css';
@import '~emoji-mart/css/emoji-mart.css';

@import 'custom/variables.scss';

// Import Bootstrap source files (Required file)
@import 'bootstrap/bootstrap.scss';
@import 'custom/custom.scss';

// This is for the icons (Required file)
@import 'icons/font-awesome/css/fontawesome-all.css';
@import 'icons/simple-line-icons/css/simple-line-icons.css';
@import 'icons/weather-icons/css/weather-icons.css';
@import 'icons/themify-icons/themify-icons.css';
@import 'icons/flag-icon-css/flag-icon.min.css';
@import 'icons/material-design-iconic-font/css/materialdesignicons.min.css';
@import 'icons/crypto-icons/cryptocoins.css';