.earningsbox{
    .c3-line{
        stroke-width:2px;
    }
}

.product-sales{
    &.c3 line, &.c3 path {
        stroke: $gray-200;

    }
    .c3-shape{
        stroke:transparent!important;
        stroke-width:3px;
    }
}

#Dashboard{
    color: #000 !important;
    h5{
        font-weight: 600;
    }
    .avgMark{
        font-size: 25px;
        font-weight: 600;
    }
    table:not(.special){
        margin-right: 20px;
        width: 100%;
        tr{
            th,td{
                vertical-align: middle;
                &:last-child{
                    margin: 0;
                }
            }
        }
    }
}

.tabless{
    thead{
        // background-color: #000;
        // color: #fff;
    }
    tr{
        th,td{
            border-bottom: 1px solid #dee2e6;
            vertical-align: middle;
        }
    }

    .progress{
        height: 12px;
        background-color: transparent;
        outline: none;
        border: none;
        box-shadow: none;
        &::after{
            content:attr(data-before);
            // content:attr(data-progress-after);
            color:black;
            transform: translate(6px,-1.8px);
            font-size: 12px;
        }
    }
    .progress-bar{
        border-radius: 1rem;
        // position: relative;
        background-color: rgb(24,124,224);
        &::before{
            content:attr(aria-valuetext);
            color:black;
            font-size: 12px;
            position: absolute;
            left: 5px;
            width: 10px;
        }
    }
}

// .progressData{
//     &::before{
//         content:attr(data-before);
//     }
// }
.table th, .table td {
    vertical-align: top;
    padding: 1rem;
}
.fix-image-size{
    width: 32px !important;
    height: 32px !important;
}

.care-summary{
    margin: 0.15rem 0 0 0;
}

.sub-instruction{
    opacity:0.5;
    font-size: 0.8rem;
}

.img-clickable{
    &:hover {
        opacity: 0.9;
        cursor: pointer;
    }
}

#comment-text-field {
    background-color: inherit;

    &::placeholder{
        color: #000
    }
}

.ant-comment-avatar, .ant-comment-avatar img {
    border-radius: 0 !important;
    width: 50px !important;
    height: 50px !important;
}

.ant-comment-content-author{
    display: flex;
    justify-content: space-between;
}

.gif-dropdown-menu{
    left: -2% !important;
}
.custom-btn {
    // padding: 15px 40px;
    // font-size: 16px;
    user-select: none;
    cursor: pointer;
    // outline: none;
    // border: none;
    // border-radius: 5px;
    // transform: scale(1);
    // box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
}
/* Adding styles on 'active' state */
  
.custom-btn:active {
    // box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    // transform: scale3d(1, 0.95, 0.95);
    transform: translateY(1px);
    /* Moving button 4px to y-axis */
}

.mentionSuggestionsEntryAvatar {
    display: block;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
}
.public-DraftEditorPlaceholder-root{
    position: absolute !important;
}
.ant-comment-nested{
    z-index: 0;
}

.underline-on-hover{
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}