.nav-tabs,
.nav-pills {
  .nav-link {
    color: $body-color;
    &:hover {
      color: $primary;
    }
  }
}

.tab-content {
  background: $white;
  &.tabcontent-border {
    border: 1px solid $border-color;
    border-top: 0px;
  }
}
// this is for custom pills
.nav-pills.custom-pills {
    .nav-link {
        border-radius: 0;
        opacity: 0.7;
        &.active {
            color: $themecolor;
            opacity: 1;
            background-color: transparent;
            border-bottom: 2px solid $themecolor;
        }
    }
}

.journey-nav,
.participation-nav {
  .dropdown-menu {
    .nav-link {
      color: $gray-800;

      &:hover {
        background-color: $primary;
        color: #fff;
      }
    }
  }
  
}

.user-nav {
  .dropdown-menu {
    .nav-link {
      color: $gray-800;

      &:hover {
        background-color: $primary;
        color: #fff;
      }
    }
  }
  
}
