.paper-bg{
  background-color: rgba(170,170,170,.5);
  width: 100%;
  height: 100vh;
}
.paper{
  width: 42%;
  min-width: 550px;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 3rem;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ul{
    padding: 0;
    list-style-type: none;
  }
  button{
    width: fit-content;
    font-size: 11px;
  }
  &-short{
    @extend .paper;
  }
}
@media screen and (max-width: 450px) {
  .paper{
    width: 300px;
    padding: 2rem 1rem;
    height: 490px;
  }
}