.user-banner {
  margin: -25px -25px 150px;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  background-color: #fff;

  &-sidebar{
    @extend .user-banner;
    margin: 0;
    padding: .5rem 0;
    z-index: 1;
  }

  .cover-photo {
    height: 480px;
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: auto;
      width: 100%;
    }
  }

  .profile-info {
    position: absolute;
    bottom: -100px;
    left: 0;
    display: flex;
    align-items: flex-end;
    padding-left: 25px;
    &-sidebar{
      bottom: 0;
    }
  }

  .profile-user-info{
    width: 90%;
    margin: auto;
    height: auto !important;
    p{
      margin-bottom: .5rem;
      font-size: 12px;
    }
  }

  .profile-picture {
    height: 350px;
    width: 350px;
    border: 5px solid #fff;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: relative;
    &-sidebar{
      @extend .profile-picture;
      margin: 0 auto;
      width: 90%;
      height: auto !important;
      // background-color: unset;
      border: none;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  
  .profile-details {
    margin-left: 15px;
    padding-right: 25px;

    p {
      margin-bottom: 0;
    }
  }


  .img-container:hover .img-overlay {
    opacity: 1;
  }
  
  .img-container:hover .profile-img1 {
    opacity: 0;
  }
  
  .profile-img1 {
    object-fit: cover;
  }
  .profile-img2 {
    object-fit: cover;
  }
  
  .img-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
  }
}

.cohort-banner {
  margin: -25px -25px 170px;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  background-color: #fff;

  .banner-image {
    height: 360px;
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      height: auto;
      width: 100%;
    }
  }

  .btn-edit-cohort {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    min-width: 10rem;
  }

  .cohort-info {
    position: absolute;
    bottom: -150px;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
  }

  .cohort-emblem {
    height: 200px;
    width: 200px;
    border: 5px solid #fff;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: relative;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
    }
  }
  
  .cohort-details {
    margin: 0.75rem 0;
  }

  .cohort-image {
    object-fit: cover;
  }
}

.cohort-values {
  padding-left: 1rem;

  li {
    margin-bottom: 0.5rem;
  }
}