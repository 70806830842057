.coaching-answer-container {
  display: flex;
  border-bottom-color: #e5e5e5;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.green-box {
  height: 30px;
  width: 50px;
  background: limegreen;
  border-radius: 2px;
}

.red-box {
  height: 30px;
  width: 50px;
  background: red;
  border-radius: 2px;
}