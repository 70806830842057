.rdt_TableHeader {
  display: none !important;
}

.datatable {
  .btn {
    font-size: 0.75rem;
  }

  .rdt_TableHeadRow {
    border-top-width: 3px;
    border-bottom-width: 3px;
    border-top-color: rgba(0,0,0,.12);
    border-top-style: solid;
  }
  .datatable{
    width: 50%;
    .rdt_TableHeadRow {
      border: none;
      min-height: 35px;
      // border-bottom: 1px solid;
      .rdt_TableCol{
        // border-bottom: 1px solid;
        &:not(.rdt_TableCol:first-child){
          // border-left: 1px solid;
        }
      }
    }
    .rdt_TableRow{
      min-height: 40px;
      border-bottom: none;
    }
  }
  .rdt_TableCol_Sortable {
  }
}

.table-scoring {
  .form-check {
    padding: 0;
  }

  .scoring {
    label {
      position: relative;
    }

    .form-check-input {
      margin: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.table-form {
  td {
    border: 0;
    vertical-align: middle;
    padding: 0.25rem;
  }
  label {
    margin-bottom: 0;
  }
  .form-group {
    margin-bottom: 0;
  }
}

.tabless{
  position: relative;
  .flex{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .scorelist{
    box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    .week-label {
      position: relative;
      width: 1.25rem;
      display: block;
      text-align: center;
      min-width: .8rem;
    }
    .step-divider {
      position: absolute;
      border-left: 1px solid #d4dde4;
      z-index: 0;
      bottom: 54px;
      transform: translateX(10px);
    }
  }
  td {
    z-index: 1;
    position: relative;
    height: 54px;
    vertical-align: middle;
    white-space: nowrap;
    border-top: 0;
    border-bottom: 0;
    .ant-progress-inner{
      background-color: transparent;
    }
  }
}

.customTable{
  td:not(:first-child){
    background-color: lightgrey;
  }
  th,td{
    font-size: 12px;
    border: 1px solid #d4dde4;
    min-width: 120px;
    max-height: 50px !important;
    height: 20px !important;
    min-height: 40px;
    padding: .3rem !important;
  }
}
.mastertable{
  th,td{
    padding: .5rem !important;
    border: 1px solid #d4dde4;
    padding:8px !important;
    height: 48px;
  }
}

.pie-chart{
  width: 100%;
}

.table-label-icon{
  width: 25px;
  margin-right: .25rem;
  display: inline-block;
  text-align: center;
  min-width:25px;
}
