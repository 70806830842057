@use "sass:map";

form {
  label {
    margin-bottom: 0.25rem;
  }

  .is-invalid {
    ~ .invalid-feedback {
      display: block;
    }
  }

  .required {
    color: map.get($theme-colors, "danger"),
  }
}

.form-review {
  
}