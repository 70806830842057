.issue{
    font-weight: bold;
    font-size: 0.95rem;
    margin: 0.4rem 0 0.8rem 0;
    padding: 0 0.25rem 0 0.25rem;
    height: 48px;
    width: 100%;
    //text-overflow: ellipsis;
    //white-space: nowrap;
    overflow: hidden; 
}

.url-clickable{
    opacity: 0.7;
    &:hover {
        opacity: 1;
        cursor: pointer;
    }
}