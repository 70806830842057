@font-face {
  font-family: 'rsuite-icon-font';
  src: url('https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.ttf') format('truetype'), url('https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.rs-icon-check::before {
  content: '\f00c';
}

.rs-progress-icon-success::before {
  content: '\f00c';
}

.rs-steps {
  min-height: 30px;
}
.rs-steps-item {
  padding-left: 40px;
  position: relative;
  overflow: hidden;
}
.rs-steps-item-icon-wrapper {
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 16px;
  line-height: 1.75;
  color: #8e8e93;
  position: absolute;
  top: 0;
  left: 0;
}
.rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-width: 1px;
  border-style: solid;
  border-color: #8e8e93;
  border-radius: 50%;
}
.rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon,
.rs-steps-item-status-error .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon {
  position: relative;
  text-indent: -9999px;
}
.rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before,
.rs-steps-item-status-error .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before {
  font-family: 'rsuite-icon-font';
  font-style: normal;
  line-height: 1;
  width: 28px;
  height: 28px;
  font-size: 12px;
  line-height: 2.33333333;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  text-indent: 0;
}
.rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  // border-color: #3498ff;
  border-color: #000;
  color: #fff;
}
.rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before {
  content: '\f00c';
  // color: #3498ff;
  color: #000;
}
.rs-steps-item-status-error .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-color: #f44336;
  color: #fff;
}
.rs-steps-item-status-error .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before {
  content: '\f00d';
  color: #f44336;
}
.rs-steps-item-status-process .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  // border-color: #3498ff;
  // background: #3498ff;
  border-color: #000;
  background: #000;
  color: #fff;
}
.rs-steps-item-status-finish .rs-steps-item-icon-wrapper,
.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
  // color: #3498ff;
  color: #000;
}
.rs-steps-item-status-error .rs-steps-item-icon-wrapper {
  color: #f44336;
}
.rs-steps-item-icon-wrapper > .rs-steps-item-icon {
  width: 100%;
  display: block;
  text-align: center;
}
.rs-steps-item-icon-wrapper > .rs-steps-item-icon .rs-icon {
  vertical-align: middle;
}
.rs-steps-item-content {
  color: #8e8e93;
  display: inline-block;
  position: relative;
  width: 100%;
}
.rs-steps-item-status-process .rs-steps-item-content {
  color: #575757;
}
.rs-steps-item-status-error .rs-steps-item-content {
  color: #f44336;
}
.rs-steps-item-title {
  position: relative;
  font-size: 16px;
  line-height: 1.875;
  min-height: 16px;
  padding-right: 10px;
  display: inline-block;
}
.rs-steps-item-title:empty {
  padding-right: 0;
}
.rs-steps-item-description {
  margin-top: 12px;
}
.rs-steps-item-tail,
.rs-steps-item-title::after {
  position: absolute;
  border-color: #c5c6c7;
}
.rs-steps-item-status-process .rs-steps-item-tail,
.rs-steps-item-status-process .rs-steps-item-title::after {
  border-color: #c5c6c7;
}
.rs-steps-item-status-finish .rs-steps-item-tail,
.rs-steps-item-status-finish .rs-steps-item-title::after {
  // border-color: #3498ff;
  border-color: #000;
}
.rs-steps-next-error .rs-steps-item-tail,
.rs-steps-next-error .rs-steps-item-title::after {
  border-color: #f44336;
}
.rs-steps-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.rs-steps-horizontal .rs-steps-item {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  min-height: 30px;
}
.rs-steps-horizontal .rs-steps-item:last-child {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.rs-steps-horizontal .rs-steps-item:last-child .rs-steps-item-title::after {
  display: none;
}
.rs-steps-horizontal .rs-steps-item:not(:first-child) {
  padding-left: 50px;
}
.rs-steps-horizontal .rs-steps-item:not(:first-child) .rs-steps-item-icon-wrapper {
  left: 10px;
}
.rs-steps-horizontal .rs-steps-item-content {
  height: 30px;
}
.rs-steps-horizontal .rs-steps-item-title::after {
  content: '';
  top: 15px;
  left: 100%;
  width: 9999px;
  border-top-width: 1px;
  border-top-style: solid;
}
.rs-steps-vertical .rs-steps-item {
  padding-bottom: 20px;
}
.rs-steps-vertical .rs-steps-item:not(:first-child) {
  margin-top: 10px;
}
.rs-steps-vertical .rs-steps-item:last-child .rs-steps-item-tail {
  display: none;
}
.rs-steps-vertical .rs-steps-item-tail {
  top: 40px;
  bottom: 0;
  left: 15px;
  border-left-width: 1px;
  border-left-style: solid;
}
.rs-steps-small {
  min-height: 24px;
}
.rs-steps-small.rs-steps-horizontal .rs-steps-item {
  min-height: 24px;
}
.rs-steps-small.rs-steps-horizontal .rs-steps-item:not(:first-child) {
  padding-left: 44px;
}
.rs-steps-small.rs-steps-vertical .rs-steps-item-tail {
  left: 12px;
}
.rs-steps-small.rs-steps-horizontal .rs-steps-item-title::after {
  top: 12px;
}
.rs-steps-small .rs-steps-item {
  padding-left: 34px;
}
.rs-steps-small .rs-steps-item-icon-wrapper {
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 1.57142857;
}
.rs-steps-small .rs-steps-item-title {
  font-size: 14px;
  line-height: 1.71428571;
}
.rs-steps-small .rs-steps-item-description {
  margin-top: 12px;
  font-size: 12px;
  line-height: 1.66666667;
}
.rs-steps-small .rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before,
.rs-steps-small .rs-steps-item-status-error .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before {
  width: 22px;
  height: 22px;
  line-height: 1.83333333;
}