.card {
  margin-bottom: $card-bottom-margin;
  @extend %card-shadow;
  .card-subtitle {
    font-weight: $font-weight-light;
    margin-bottom: 10px;
    color: $text-muted;
  }
  .card-title {
    position: relative;
    font-weight: $font-weight-medium;
  }
  .card-actions {
    float: $rgt;
    a {
      padding: 0 5px;
      cursor: pointer;
    }
  }
  .card-header .card-title {
    margin-bottom: 0px;
  }

  &.card-tasks {
    h3 {
      color: $body-color;
    }

    .data-widget {
      height: 6.25rem;
    }

    .widget-icon {
      padding: 0;
      height: 100%;
    }

    .card-details {
      display: flex;
      justify-content: space-between;
      height: 100%;
      flex-direction: column;
      text-align: left;
      padding: 0.625rem 0;
      font-size: 0.875rem;

      .btn {
        text-align: left;
        font-weight: 600;
      }

      h3 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        height: 3 * 1rem * $header-line-height;
        white-space: normal;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
  .data-widget {
    display: flex;
    align-items: center;
  
    h3 {
      font-size: 1rem;
      margin-bottom: 0.125rem;
    }

    p {
      font-size: 0.75rem;
      margin-bottom: 0.125rem;
    }

    .btn {
      padding: 0;
    }

    .widget-icon {
      color: #fff;
      padding: 1rem;
      background-color: $themecolor;
      font-size: 3rem;
      margin-right: 1rem;
    }
  
    .card-subtitle {
      margin-bottom: 0;
    }
  }
}

.card-cohort {
  display: flex;
  min-height: 25rem;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.card-intro {
  display: flex;
  min-height: 25rem;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.card-summary {
  min-height: 25rem;
  padding: 2rem 0;

  &.no-summary {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.card-alt {
  margin: 0 -20px;
  background: $card-alt;
}

.card-group {
  margin-bottom: $card-bottom-margin;
  .card {
    border-right: 1px solid $border-color;
  }
}

.card-fullscreen {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
}

.card-hover {
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
  &:hover {
    -webkit-transform: translateY(-4px) scale(1.01);
    -moz-transform: translateY(-4px) scale(1.01);
    -ms-transform: translateY(-4px) scale(1.01);
    -o-transform: translateY(-4px) scale(1.01);
    transform: translateY(-4px) scale(1.01);
    -webkit-box-shadow: 0 14px 24px rgba(62, 57, 107, 0.1);
    box-shadow: 0 14px 24px rgba(62, 57, 107, 0.1);
  }
}
.draggable-cards .card-header {
  cursor: move;
}
.card-moved .card {
  background: $info;
  color: $white;
}

.accordion {
  .card,
  .btn,
  .btn:hover {
    box-shadow: none;
    margin-bottom: 1px;
  }
}

.card-activity {
  img {
    width: 100%;
    height: 200px;
    margin-bottom: 1rem;
  }

  .card-info {
    margin-bottom: 1rem;
    min-height: 10rem;
  }

  // .card-actions {

  // }
}

.card-task-review {
  height: calc(100% - 30px);
}