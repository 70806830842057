.my-key-stats{
    padding: 0 15%;

    .rdt_TableRow{
        border-bottom: 1px solid #000000;
    }
    
    .rdt_TableHeadRow{
        border-top: 1px solid #000000;
    }

    .rdt_TableCol{
        font-weight: bold;
    }

    .rdt_TableCol, .rdt_TableCell{
        justify-content: center;
    }
}

